.accordion {
	.card {
		.card-header {
			background-color: $frame-border-color;
			.card-title {
				color: $primary-500;

				&.collapsed {
					color: $fusion-100;
				}
			}
		}
	}
	&.accordion-clean {
		.card-header {
			background: $white;
		}
	}

	&.accordion-hover {
		.card-header {
			background:$white;
			&:hover {
				.card-title.collapsed {
					color: $white;
					background-color: $primary-300;
				}
			}
		}
		.card-title:not(.collapsed) {
			color: $white;
			background-color: $primary-500;
			
		}
	}
}
