.daterangepicker  table tr td.old, 
.daterangepicker  table tr td.new {
	color: lighten($fusion-50, 10%);
}

.daterangepicker  table tr td.active:active, 
.daterangepicker  table tr td.active.highlighted:active, 
.daterangepicker  table tr td.active.active, 
.daterangepicker  table tr td.active.highlighted.active,
.daterangepicker  table tr td.selected,
.daterangepicker  table tr td.selected.highlighted,
.daterangepicker  table tr td span.active.active,
.daterangepicker  table tr td span.focused {
	background-color: $primary-400;
	color: $white;
}


.daterangepicker  table tr td.active:active:hover, 
.daterangepicker  table tr td.active.highlighted:active:hover, 
.daterangepicker  table tr td.active.active:hover, 
.daterangepicker  table tr td.active.highlighted.active:hover, 
.daterangepicker  table tr td.active:active:focus, 
.daterangepicker  table tr td.active.highlighted:active:focus, 
.daterangepicker  table tr td.active.active:focus, 
.daterangepicker  table tr td.active.highlighted.active:focus, 
.daterangepicker  table tr td.active:active.focus, 
.daterangepicker  table tr td.active.highlighted:active.focus, 
.daterangepicker  table tr td.active.active.focus, 
.daterangepicker  table tr td.active.highlighted.active.focus,
.daterangepicker  table tr td.selected:active:hover, 
.daterangepicker  table tr td.selected.highlighted:active:hover, 
.daterangepicker  table tr td.selected.active:hover, 
.daterangepicker  table tr td.selected.highlighted.active:hover,
.daterangepicker  table tr td.selected:active:focus, 
.daterangepicker  table tr td.selected.highlighted:active:focus, 
.daterangepicker  table tr td.selected.active:focus, 
.daterangepicker  table tr td.selected.highlighted.active:focus, 
.daterangepicker  table tr td.selected:active.focus, 
.daterangepicker  table tr td.selected.highlighted:active.focus, 
.daterangepicker  table tr td.selected.active.focus, 
.daterangepicker  table tr td.selected.highlighted.active.focus,
.daterangepicker  table tr td.selected:hover, 
.daterangepicker  table tr td.selected.highlighted:hover {
	background-color: $primary-600;
	color: $white;
}


.daterangepicker .calendar-table .next,
.daterangepicker .calendar-table .prev  {

    span {
    	border-color: #a1a8c3;
    }
}

.daterangepicker .in-range.available {
	background-color: $warning-100;
}

.daterangepicker .off.ends.in-range.available {
	background-color: $warning-50;
}


.daterangepicker td.available:hover, 
.daterangepicker th.available:hover {
	background-color: $warning-300;
}


.daterangepicker .calendar-table table thead tr th {

	&.month {
		color: #a1a8c3;
	}
}

.daterangepicker .ranges li {

    &.active {
    	background-color: $primary-500;
    }
}
