.header-icon {		
	color: $base-text-color;

	&:not(.btn) {


		>[class*='fa-']:first-child,
		>.#{$cust-icon-prefix}:first-child {
				color: $header-link-color;
			}

			&:hover {
				&>[class*='fa-']:only-child,
				&>.#{$cust-icon-prefix} {
					color: $header-link-hover-color;
				}
			}


		&[data-toggle="dropdown"] {


			&[aria-expanded="true"] {
				color: $header-link-hover-color;
				
				>[class*='fa-']:first-child,
				>.#{$cust-icon-prefix}:first-child {
					color: $header-link-hover-color !important;
				}

			}

			/* header dropdowns */
			/* note: important rules to override popper's inline classes */
			& + .dropdown-menu {
				border-color: $header-border-color;
			}

			/* end header dropdowns */
			
		}

	}

	&:hover{
		color:$header-link-hover-color;
	}
}

.page-header {
	background-color: $header-bg;
}