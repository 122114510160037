/* backgrounds */
.bg-white { background-color: $white; color: $base-text-color}
.bg-faded {	background-color: $frame-border-color }
.bg-offwhite-fade {	@include gradient-img($white, lighten($color-fusion, 66%)) }
.bg-subtlelight { background-color: lighten($color-primary, 44%) }
.bg-subtlelight-fade { @include gradient-img($white, lighten($color-primary, 44%))	}
.bg-highlight { background-color: lighten($warning-50, 9%)}

.bg-gray-50  { background-color: $gray-50;  }
.bg-gray-100 { background-color: $gray-100; }
.bg-gray-200 { background-color: $gray-200; }
.bg-gray-300 { background-color: $gray-300; }
.bg-gray-400 { background-color: $gray-400; }
.bg-gray-500 { background-color: $gray-500; }
.bg-gray-600 { background-color: $gray-600; }
.bg-gray-700 { background-color: $gray-700; }
.bg-gray-800 { background-color: $gray-800; }
.bg-gray-900 { background-color: $gray-900; }

/* borders */
.border-faded { border: 1px solid rgba($fusion-900, 0.07) }


/* hover any bg */
/* inherits the parent background on hover */
.hover-bg {
	background: $white;
}

/* states */
.state-selected {
	background: lighten($info-500, 41%) !important;
}

/* demo window */
.demo-window {
	box-shadow: 0 2px 10px rgba(0,0,0,0.12);

	&:before {
		background: #e5e5e5;
	}

	&:after,
	.demo-window-content:before,
	.demo-window-content:after {
		background: #ccc;
	}

}