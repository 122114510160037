//chartist
$ct-class-series: ct-series;
$ct-class-point: ct-point;
$ct-class-line: ct-line;
$ct-class-bar: ct-bar;
$ct-class-slice-donut: ct-slice-donut;
$ct-class-slice-pie: ct-slice-pie;
$ct-class-slice-donut-solid: ct-slice-donut-solid;
$ct-class-area: ct-area;


$ct-series-names: (a, b, c, d, e, f, g, h, i, j, k, l, m, n, o);
$ct-series-colors: (
  $primary-500, //red
  $danger-500, //light red
  $warning-500, //yellow
  $info-500, //dark orange
  $fusion-500, //ash
  $success-500, //green
  $info-500, //blue
  $primary-900, //purple
  $danger-100, //lighter red
  $warning-200, //faded orange
  $danger-900, //faded yellow
  $fusion-300, //light gray
  $success-300, //light green
  $info-300, //light blue
  $primary-300 //light purple
);

@mixin ct-chart-series-color($color) {
  .#{$ct-class-point}, .#{$ct-class-line}, .#{$ct-class-bar}, .#{$ct-class-slice-donut} {
    stroke: $color;
  }
  .#{$ct-class-slice-pie}, .#{$ct-class-slice-donut-solid}, .#{$ct-class-area} {
    fill: $color;
  }
}


@for $i from 0 to length($ct-series-names) {
  .#{$ct-class-series}-#{nth($ct-series-names, $i + 1)} {
    $color: nth($ct-series-colors, $i + 1);
    @include ct-chart-series-color($color);
  }
}

